<template>
	<errorContainer :error="error">
		<transition name="slide">
			<div id="greenReportingScreen">
				<div class="form">
					<!-- <b-row>
						<b-col>
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(897, 'Carbon Clap') }}
							</h1>
						</b-col>
					</b-row> -->
					<b-row class="tab-carbon-clap">
						<b-col>
							<b-card no-body class="pos-relative">
								<b-tabs
									cards
									active-nav-item-class="font-weight-bold"
									active-tab-class="font-weight-bold"
									v-model="tabActive"
									@input="handleInputTabs"
									class="px-4"
								>
									<b-tab>
										<template #title>
											<div>
												<b-spinner v-if="loadingTitleTableForecast" small />
												{{ FormMSG(487, 'Forecast') }}
											</div>
										</template>
										<b-row>
											<b-col class="p-0">
												<reporting-form
													:carbon-clap-data="carbonClapDataForecast"
													:final-report="false"
													:is-api="true"
													:external-submit="submitForecast"
													@reporting:change="handleReportingForecastChange"
													@reporting-form:error="handleReportingFormError"
												/>
											</b-col>
										</b-row>
									</b-tab>
									<b-tab>
										<template #title>
											<div>
												<b-spinner v-if="loadingTitleTableFinalReport" small />
												{{ FormMSG(488, 'Final Report') }}
											</div>
										</template>
										<b-row>
											<b-col class="p-0">
												<reporting-form
													:carbon-clap-data="carbonClapDataFinalReport"
													disable-fields
													:final-report="true"
													:is-api="true"
													:external-submit="submitFinalReport"
													@reporting:change="handleReportingFinalReportChange"
													@reporting-form:error="handleReportingFormError"
												/>
											</b-col>
										</b-row>
									</b-tab>
								</b-tabs>
							</b-card>
						</b-col>
					</b-row>
					<b-modal
						v-model="showModalWarning"
						header-class="header-class-modal-doc-package"
						header-bg-variant="warning"
						header-border-variant="warning"
						:ok-title="FormMSG(578, 'Go to project config')"
						hide-header-close
						no-close-on-backdrop
						no-close-on-esc
						ok-variant="primary"
						ok-only
						@ok="handleOk"
						modal-class="mui-animation"
						:fade="false"
					>
						<template #modal-title>
							<div>
								<component :is="getLucideIcon('AlertTriangle')" :size="20" />
								{{ FormMSG(70, 'Carbon clap login not found') }}
							</div>
						</template>
						<div>
							{{ FormMSG(76, "You don't have access, you have to enter the login information for the project configuration") }}
						</div>
					</b-modal>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { store } from '@/store';

import ReportingForm from '@/modules/carbonclap/components/reporting/ReportingForm';

import { getProjectCarbonClap, reportCarbonClap } from '@/cruds/carbon.crud';

const CC_INIT_VALUE = {
	// CONFIGURATION
	ccProjectName: '',
	productionType: '',
	budget: 0,
	totalProduction: 0,
	numberOfEpisode: 0,
	filmLength: 0,
	shootingFormat: '',
	startDate: '',
	endDate: '',
	country: '',
	coProdCountries: '',

	// DECOR
	costPurchaseSetsTotal: 0,
	costMakingSetsTotal: 0,
	costRentingSetsTotal: 0,
	ecoConceptionSetTotal: false,
	sortingWaste: false,
	materialSorted: false,
	paintLiterWasteTotal: 0,
	cardboardWasteTotal: 0,
	woodWasteTotal: 0,
	steelWasteTotal: 0,
	plasticWasteTotal: 0,

	// HMC Hair Makeup
	costumeBuyTotal: 0,
	costumeMakingTotal: 0,
	costumeRentalTotal: 0,
	costumeReuse: false,
	makeupTotal: 0,
	hairCostTotal: 0,

	// SET RELATED
	setSmallConsumableTotal: 0,

	// REGIE
	setMaterialSorted: false,
	setPaintLiterWasteTotal: 0,
	setCardboardWasteTotal: 0,
	setWoodWasteTotal: 0,
	setSteelWasteTotal: 0,
	setPlasticWasteTotal: 0,
	setMixedWasteTotal: 0,

	// TECHNIQUE DE PRODUCTION
	nbrBatteriesThrown: 0,
	amountCameraRental: 0,
	amountLightingRental: 0,
	amountMachineryRental: 0,
	amountSoundMaterialRental: 0,
	amountOfFilmsSupport: 0,
	amountOfSpecialEffect: 0,
	sumCarsLogesKm: 0,
	sizeOfCarLoge: '',
	energyTypeOfCarLoge: '',
	volumeEnergyCarLoge: 0,

	// POST PRODUCTION
	numDaysVideoEditing: 0,
	videoEditingCountry: '',
	numDaysSoundEditing: 0,
	soundEditingCountry: '',
	numDaysVfx: 0,
	vfxCountry: '',
	numDaysColorGrading: 0,
	colorGradingCountry: '',
	numDaysMasteringAndLab: 0,
	masteringAndLabCountry: '',

	// ARCHIVING
	dataArchivedLTO: 0,
	numCopiesArchivedLTO: 0,
	dataArchivedHDD: 0,
	numCopiesArchivedHDD: 0,
	dataArchivedOnCloud: 0,
	numCopiesArchivedOnCloud: 0,

	// TRANSPORTATION
	sumDieselKm: 0,
	sumPetrolKm: 0,
	sumHybridKm: 0,
	sumElectricKm: 0,
	sumTaxiKm: 0,
	sumPlaneKm: 0,
	sumFreightSmallTruckKm: 0,
	sumFreightSmallTruckKg: 0,
	sumFreightLargeTruckKm: 0,
	sumFreightLargeTruckKg: 0,
	sumMotorcycleLarge: 0,
	sumScooterGasoline: 0,
	sumScooterElectric: 0,
	sumPrivateJet: 0,
	sumIntercityTrain: 0,
	sumBus: 0,
	sumFerryBoat: 0,
	sumFreightTrain: 0,
	sumFreightPlane: 0,
	sumContainerShip: 0,
	sumFreightTrainKg: 0,
	sumFreightPlaneKg: 0,
	sumContainerShipKg: 0
};

const fieldOmited = ['id'];

export default {
	name: 'index',

	mixins: [languageMessages, globalMixin],

	components: {
		ReportingForm
	},

	data() {
		return {
			error: {},

			loading: false,

			carbonClapDataForecast: {},
			carbonClapDataFinalReport: {},

			originalCarbonClapDataForecast: {},
			originalCarbonClapDataFinalReport: {},

			loadingTitleTableForecast: false,
			loadingTitleTableFinalReport: false,

			showModalWarning: false,

			submitForecast: false,
			submitFinalReport: false,

			tabActive: 1
		};
	},

	async beforeRouteLeave(to, from, next) {
		if (this.showModalWarning === true) {
			next();
		}
		const action = async () => {
			if (JSON.stringify(this.carbonClapDataForecast) !== JSON.stringify(this.originalCarbonClapDataForecast)) {
				this.submitForecast = true;
			}

			if (JSON.stringify(this.carbonClapDataFinalReport) !== JSON.stringify(this.originalCarbonClapDataFinalReport)) {
				this.submitFinalReport = true;
			}

			setTimeout(() => {
				next();
			}, 1000);
		};

		const resetAction = async () => {
			next();
		};

		if (
			JSON.stringify(this.carbonClapDataForecast) !== JSON.stringify(this.originalCarbonClapDataForecast) ||
			JSON.stringify(this.carbonClapDataFinalReport) !== JSON.stringify(this.originalCarbonClapDataFinalReport)
		) {
			this.confirmModal(action, this.FormMSG(985, 'Would you like to save your changes ?'), 'success', resetAction);
		} else {
			next();
		}
	},

	async created() {
		if (store.state.myProject.ccLogin === '' && store.state.myProject.ccPassword === '') {
			this.showModalWarning = true;
		} else {
			await this.getProjectCarbonClap(true, 'loadingFinaReport');
		}
	},

	methods: {
		handleReportingFormError(payload) {
			this.error = payload;
		},
		handleOk() {
			// this.showModalWarning = false;
			this.$router.push('/settings');
		},
		async handleInputTabs(payload) {
			let finalReport = false;
			let fieldLoading = 'loadingTitleTableForecast';

			if (payload === 1) {
				finalReport = true;
				fieldLoading = 'loadingTitleTableFinalReport';
			}

			await this.getProjectCarbonClap(finalReport, fieldLoading);
		},
		async getProjectCarbonClap(finalReport = false, fieldLoading) {
			try {
				this[fieldLoading] = true;
				const result = await getProjectCarbonClap(finalReport);

				if (finalReport === false) {
					this.carbonClapDataForecast = result;
					this.originalCarbonClapDataForecast = result;
				} else if (finalReport === true) {
					this.carbonClapDataFinalReport = result;
					this.originalCarbonClapDataFinalReport = result;
				}

				this[fieldLoading] = false;
			} catch (e) {
				this.error = e;
				this[fieldLoading] = false;
			}
		},
		handleReportingFinalReportChange(payload) {
			this.carbonClapDataFinalReport = {
				...payload
			};
		},
		handleReportingForecastChange(payload) {
			this.carbonClapDataForecast = {
				...payload
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-carbon-clap {
	// background-color: #ffffff;
	// border-radius: 2px;

	.nav-tabs .nav-item {
		width: 130px;
		text-align: center;
	}
}
</style>
